const REDIRECT_PAGES = Object.freeze({
  CSNEXT: 'csnext',
  DASHBOARD: 'dashboard',
  LICENSE: 'license',
  LEARN: 'learn',
  ANALYTICS: 'analytics',
  NONE: 'none',
  UNKNOWN: 'unknown',
})

const PAGE_URLS = Object.freeze({
  [REDIRECT_PAGES.CSNEXT]: ['localhost:3000', 'dev-csnext.firialabs.com', 'make.firialabs.com', 'sim.firialabs.com'],
  [REDIRECT_PAGES.DASHBOARD]: ['dashboard.firialabs.com, dev-dashboard.firialabs.com', 'localhost:3002'],
  [REDIRECT_PAGES.LICENSE]: ['license.firialabs.com, dev-license.firialabs.com', 'localhost:3004'],
  [REDIRECT_PAGES.LEARN]: ['learn.firialabs.com', 'localhost:3007'],
  [REDIRECT_PAGES.ANALYTICS]: ['analytics.firialabs.com', 'dev-analytics.firialabs.com', 'localhost:3008'],
})

function getRedirectPage() {
  let href = window.location.href
  if (!href.includes('returnTo=')) {
    return REDIRECT_PAGES.NONE
  }

  let redirectTo = href.split('returnTo=')[1]
  for (let [page, urls] of Object.entries(PAGE_URLS)) {
    for (let url of urls) {
      if (redirectTo.includes(url)) {
        return page
      }
    }
  }

  return REDIRECT_PAGES.UNKNOWN
}

const returnURL = getRedirectPage()

export { returnURL, REDIRECT_PAGES }