import authFetchHandler from './authFetchHandler'

const getRedirectURL = async () => {
  const queryString = window.location.search
  if (!queryString) {
    return
  }

  const urlParams = new URLSearchParams(queryString)
  const leaveQuery = urlParams.get('redirect')?.toLowerCase()
  if (leaveQuery && leaveQuery.includes('n')) {
    return
  }

  let returnQuery = urlParams.get('returnTo')?.toLowerCase()
  if (!returnQuery) {
    returnQuery = urlParams.get('r')?.toLowerCase()
  }
  if (returnQuery === 'community.firialabs.com') {
    const sso = urlParams.get('sso')
    const sig = urlParams.get('sig')
    const resp = await authFetchHandler(`session/discourse?sso=${sso}&sig=${sig}`)
    const json = await resp.json()
    return json?.redirectUrl
  }

  if (returnQuery) {
    if (returnQuery.includes('http')) {
      // Assuming this is a full URL/UCI
      return decodeURIComponent(returnQuery)
    } else if (returnQuery.includes(':')) {
      // This is probably localhost because it includes a port
      return `//${returnQuery}/`
    } else if (returnQuery.includes('.') || returnQuery.includes('/')) {
      // Assuming this is also a full URL/URI but without a protocol
      return `https://${returnQuery}/`
    } else {
      // Assuming this is a subdomain of firialabs.com
      return `https://${returnQuery}.firialabs.com/`
    }
  }
}

const handleLoginPortalRedirect = async () => {
  const redirectURL = await getRedirectURL()
  console.log('URL', redirectURL)
  if (redirectURL) {
    setTimeout(() => {
      window.location.href = redirectURL
    }, 2000)
  }
}

export default handleLoginPortalRedirect