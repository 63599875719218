import React, { createContext, useState, useContext } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { useSnackbar } from 'notistack'
import fetchHandler from './tools/tracking/fetchHandler'
import { firebaseDomain } from './tools/domain'
import logger from './tools/tracking/logger'
import handleGenerateSessionIdWithTokenId from './tools/auth/handleGenerateSessionIdWithTokenId'
import handleLogout from './tools/auth/handleLogout'
import pollSessionIdCookie from './tools/auth/pollSessionIdCookie'
import { setUserAuthCallbacks, hasCookieSessionIdInitializationCompleted } from './tools/auth/userAuthStore'
pollSessionIdCookie()

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBBASE_API_KEY,
  authDomain: process.env.REACT_APP_WEB_HOST_URI,
  projectId: process.env.REACT_APP_FIREBASE_PROJ_ID,
  messagingSenderId: process.env.REACT_APP_MSG_SENDER_ID,
})

firebase.auth().useDeviceLanguage()
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)  // We're using cookies and managing persistence there
process.env.NODE_ENV === 'development' && firebase.auth().useEmulator('http://localhost:9099', { disableWarnings: true })

export const LoginActions = Object.freeze({
  // e.g. Login, Logout
  SIGNUP: Symbol('sign up'),
  EMAIL_LOGIN: Symbol('email login'),
  GOOGLE_LOGIN: Symbol('google login'),
  FORGOT_PASSWORD: Symbol('forgot password'),
  LOGOUT: Symbol('logout'),
  SEND_VERIFICATION: Symbol('send verification'),
  TOKEN_LOGIN: Symbol('token login'),
})

async function handleNewUserCreation(user) {
  const idToken = await user.getIdToken()
  const headers = { Authorization: 'Bearer ' + idToken}
  return await fetchHandler(`${firebaseDomain}/user`, {
    method: 'POST',
    headers,
  })
}

const LoginStateContext = createContext()
const LoginDispatchContext = createContext()

export const LoginProvider = ({ children }) => {
  const snacks = useSnackbar()
  const [user, setUser] = useState(null)
  const [loginReady, setLoginReady] = useState(hasCookieSessionIdInitializationCompleted())
  const [tokenValidationError, setTokenValidationError] = useState(false)
  const tokenValidationErrorDebounce = React.useRef(null)

  React.useEffect(() => {
    const handleTokenError = () => {
      if (!tokenValidationError) {
        return
      }

      if (!!tokenValidationErrorDebounce.current && tokenValidationErrorDebounce.current + 1000 > Date.now()) {
        return
      }

      tokenValidationErrorDebounce.current = Date.now()
      setLoginReady(true)
      dispatch({ type: LoginActions.LOGOUT })
      setUser(false)
      snacks.enqueueSnackbar('Whoops! Something went wrong on our end, please log in again.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        autoHideDuration: 7000,
      })
    }

    handleTokenError()
    setTokenValidationError(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ tokenValidationError ])

  setUserAuthCallbacks(
    (user) => {
      setUser(user)
    },
    () => {
      setLoginReady(true)
    }
  )

  const generationTriggered = React.useRef(false)
  firebase.auth().onAuthStateChanged(async (newUser, err) => {
    logger.debug('Auth state changed.')
    if (newUser) {
      if (generationTriggered.current === true) {
        return
      }
      generationTriggered.current = true
      // if (newUser.metadata.creationTime === newUser.metadata.lastSignInTime) {
      //   console.log('BRAND NEW USER!!!')
      // }
      const idToken = await newUser.getIdToken()
      setTokenValidationError(await handleGenerateSessionIdWithTokenId(idToken))
      setUser(newUser)
    }
  })


  const dispatch = async (action) => {
    switch (action.type) {
      case LoginActions.SIGNUP: {
        const signInResult = await firebase
          .auth()
          .createUserWithEmailAndPassword(action.email, action.password)
        await handleNewUserCreation(signInResult.user)
        try {
          firebase.auth()
            .currentUser.sendEmailVerification()
        } catch (e) {
          console.error('Couldn\'t send verification email')
          // TODO don't just print!
        }
        break
      }
      // { type: ..., email: String, password: String, shouldPersist: Bool }
      case LoginActions.EMAIL_LOGIN: {
        // Don't try/catch here -- UI should show if email/password do not match
        // await setAuthPersistence(action.shouldPersist)  // TODO look for a new way to set persistence
        const signInResult = await firebase
          .auth()
          .signInWithEmailAndPassword(action.email, action.password)
        if (signInResult.additionalUserInfo?.isNewUser) {
          await handleNewUserCreation(signInResult.user)
          await firebase.auth()
            .currentUser.sendEmailVerification()
        }
        break
      }
      // { type: ... }
      case LoginActions.GOOGLE_LOGIN: {
        // await setAuthPersistence(true)  // TODO look for a new way to set persistence
        const signInResult = await firebase.auth()
          .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        if (signInResult.additionalUserInfo?.isNewUser) {
          await handleNewUserCreation(signInResult.user)
          // TODO add to CRM?
        }
        break
      }
      // { type: ..., email: String }
      case LoginActions.FORGOT_PASSWORD:
        await firebase.auth().sendPasswordResetEmail(action.email)
        break
      // { type: ... }
      case LoginActions.LOGOUT:
        try {
          await firebase.auth().signOut()
        } catch (err) {
          console.log(err)
        }
        handleLogout()
        break
      case LoginActions.SEND_VERIFICATION:
        try {
          await firebase.auth().currentUser.sendEmailVerification()
        } catch (err) {
          console.log(err)
        }
        setLoginReady(true)
        break
      default:
        throw new Error(`Unhandled action type: ${action.type.toString()}`)
    }
  }
  return (
    <LoginStateContext.Provider value={{ user, loginReady }}>
      <LoginDispatchContext.Provider value={dispatch}>
        {children}
      </LoginDispatchContext.Provider>
    </LoginStateContext.Provider>
  )
}

export const useLogin = () => {
  const state = useContext(LoginStateContext)
  const dispatch = useContext(LoginDispatchContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error('useLogin must be used within a child of a LoginProider')
  }
  return [state, dispatch]
}
