import React, { useState, useEffect, useDebugValue } from 'react'
import './App.css'

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import green from '@mui/material/colors/green'
import yellow from '@mui/material/colors/yellow'
import CircularProgress from '@mui/material/CircularProgress'
import { useSnackbar } from 'notistack'
import { returnURL, REDIRECT_PAGES } from './returnURLTools'

import { useLogin } from './LoginContext'
import SignOut from './SignOut'
import SignInSignUp from './SignInSignUp'
import LoginParent from './LoginParent'
import CustomerSupportFab from './CustomerSupportFab'
const willRedirect = new URLSearchParams(window.location.search).get('returnTo')?.toLowerCase()

const theme = createTheme({
  palette: {
    primary: green,
    secondary: yellow,
  },
})

const DisplayComponent = ({ willRedirect, loggedIn, returnTo, setWindowTitle, loginPending, setLoginPending }) => {
  return (
    <>
      {loggedIn ?
        <SignOut redirect={returnTo} willRedirect={willRedirect} setWindowTitle={setWindowTitle} loginPending={loginPending} setLoginPending={setLoginPending} /> :
        <SignInSignUp setWindowTitle={setWindowTitle} loginPending={loginPending} setLoginPending={setLoginPending} />}
    </>
  )
}

function App() {
  const [loginState] = useLogin()
  const [loggedIn, setLoggedIn] = useState(false)
  const [windowTitle, setWindowTitle] = useState('')
  const [loginPending, setLoginPending] = useState(false)
  const snacks = useSnackbar()

  useDebugValue(loginState.loginReady, loginState.loginReady ? 'Login Ready' : 'Waiting on Login State')

  useEffect(() => {
    if (loginState.loginReady) {
      setLoggedIn(!!loginState?.user)
      setLoginPending(false)  // Is this the right place?
    } else {
      setWindowTitle('')
    }
  }, [loginState])

  React.useEffect(() => {
    if (returnURL === REDIRECT_PAGES.CSNEXT) {
      snacks.enqueueSnackbar(
        <span>
            Looking for <b>JumpStart</b> or <b>LiftOff</b> for <b>micro:bit</b>?<br />
          <center>It's moved to <a style={{color: 'orange'}} href={'https://makebit.firialabs.com'}>makebit.firialabs.com</a>!</center>
        </span>, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          preventDuplicate: true,
          key: 'microbit-warning',
          persist: true,
        })
    }
    if (returnURL === REDIRECT_PAGES.LICENSE) {
      snacks.enqueueSnackbar('Please log in with ... {license message}', {
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        preventDuplicate: true,
        key: 'license-warning',
        persist: true,
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='App'>
      <CustomerSupportFab />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LoginParent title={windowTitle}>
            {loginState && loginState.loginReady ?
              <DisplayComponent willRedirect={willRedirect} setWindowTitle={setWindowTitle} loginPending={loginPending} setLoginPending={setLoginPending} loggedIn={loggedIn} /> :
              <CircularProgress sx={{ margin: 8 }} />
            }
          </LoginParent>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  )
}

export default App
